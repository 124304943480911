import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from'../assets/Logo.png';
import '../styles.css';
import styles from '../LoginPage.module.css';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const response = await fetch('https://medicids-backend-34db21443628.herokuapp.com/api/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const result = await response.json();

      if (response.ok) {
        const { uuid } = result; // Assuming the server returns the UUID upon successful login
        navigate(`/user/${uuid}`); // Redirect to the user's page with their UUID
      } else {
        setError(result.error || 'Invalid email or password');
      }
    } catch (err) {
      console.error(err);
      setError('An error occurred while logging in.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page-wrapper"
    style={{backgroundColor: '#ffffff'}}>
    <div className={styles.container}>
      <div className="section-content">
      <img src={logo} alt="App Title" className="login-logo" />
        <h1>Login</h1>
        <h4>Please enter your email and password</h4>
        <form onSubmit={handleLoginSubmit}>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />

            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </div>

          {error && <p className="error-message">{error}</p>}

          <button type="submit" className="add-button" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
      </div>
    </div>
    </div>
  );
}

export default LoginPage;

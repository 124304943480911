import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import '../../styles.css';
import Allergy from './Allergy';
import Contact from './Contact';
import DNR from './DNR';
import Medical from './Medical';
import Medication from './Medication';
import PersonalInfo from './PersonalInfo';

function EditPage({ formData, setFormData }) {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const [fileUploaded, setFileUploaded] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [missingFields, setMissingFields] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  // Refs for each section to allow scrolling
  const personalRef = useRef(null);
  const contactRef = useRef(null);
  const medicationRef = useRef(null);
  const allergyRef = useRef(null);
  const dnrRef = useRef(null); // Ref for DNR section

  const [removedMedications, setRemovedMedications] = useState([]);
  const [removedAllergies, setRemovedAllergies] = useState([]);
  const [removedConditions, setRemovedConditions] = useState([]);   // Track removed conditions
  const [removedSurgeries, setRemovedSurgeries] = useState([]);     // Track removed surgeries
  const [removedContacts, setRemovedContacts] = useState([]);

  const [dnrDocumentUrl, setDnrDocumentUrl] = useState(null);


   // Assigns class to body to apply background image without applying to all pages
   useEffect(() => {
    document.body.classList.add('background-image');

    // Clean up: Remove the class when the component unmounts
    return () => {
      document.body.classList.remove('background-image');
    };
  }, []);


  // Stop enter key from submitting form
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };


  useEffect(() => {
    if (!isAuthenticated) {
      navigate(`/user/${uuid}`);
    }
  }, [isAuthenticated, navigate, uuid]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}`);
        // const response = await fetch(`http://localhost:5000/api/users/uuid/${uuid}`);

        if (!response.ok) {
          throw new Error('User not found');
        }
        const data = await response.json();

        // Ensure countryCode exists for each contact
        data.contacts = data.contacts.map(contact => ({
          ...contact,
          countryCode: contact.countryCode || '+1',
        }));
        data.medications = data.medications || [];

        setFormData(data);
        setInitialData(data);

        if (data.dnr_document && data.dnr_document.data) {
          setFileUploaded(true);
          setDnrDocumentUrl(`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}/dnr`);
          // setDnrDocumentUrl(`http://localhost:5000/api/users/uuid/${uuid}/dnr`);

        } else {
          setFileUploaded(false);
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
      }
    };

    fetchUserData();
  }, [uuid, setFormData]);

  // Utility function to remove completely blank fields before submission
  const cleanFormData = (data) => {
    return {
      ...data,
      // Remove blank medications (where name, dosage, and frequency are all empty)
      medications: data.medications.filter(
        (medication) => medication.name.trim() !== '' || medication.dosage.trim() !== '' || medication.frequency.trim() !== ''
      ),
  
      // Remove blank medical conditions (where the condition is empty)
      conditions: data.conditions.filter(
        (condition) => condition.trim() !== ''
      ),
  
      // Remove blank surgeries (where the surgery field is empty)
      surgeries: data.surgeries.filter(
        (surgery) => surgery.trim() !== ''
      ),
  
      // Remove blank contacts (where name, number, and relationship are all empty)
      contacts: data.contacts.filter(
        (contact) => contact.name.trim() !== '' || contact.number.trim() !== '' || contact.relationship.trim() !== ''
      ),
  
      // Remove blank allergies (where name and comments are all empty)
      allergies: data.allergies.filter(
        (allergy) => allergy.name.trim() !== '' || allergy.comments.trim() !== ''
      ),
    };
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
  
    // Assuming cleanFormData processes the data, including contacts
    const cleanedFormData = cleanFormData(formData);
    console.log('Cleaned Form Data:', cleanedFormData);


    let validationFailed = false;
    let firstInvalidSection = null;
    const newMissingFields = {};
  
    // Date of Birth validation
    const validateDateOfBirth = () => {
      const today = new Date();
      const dob = new Date(cleanedFormData.date_of_birth);
      let errors = { ...formErrors };
  
      if (cleanedFormData.date_of_birth && dob >= today) {
        errors.date_of_birth = 'Please enter a valid date of birth in the past.';
        setFormErrors(errors);
        setMissingFields((prev) => ({
          ...prev,
          personalInfo: true,
        }));
        if (!firstInvalidSection) firstInvalidSection = personalRef;
        validationFailed = true;
      } else {
        delete errors.date_of_birth;
        setFormErrors(errors);
        setMissingFields((prev) => ({
          ...prev,
          personalInfo: false,
        }));
      }
    };
  
    validateDateOfBirth();
  
    // Validate phone numbers (countryCode and number separately)
    const validPhoneNumber = /^\+?[1-9]\d{1,14}$/;
    let hasInvalidContact = false;
  
    cleanedFormData.contacts.forEach((contact, index) => {
      const hasAnyFieldFilled = contact.name || contact.number || contact.relationship;
  
      if (hasAnyFieldFilled) {
        // Validate phone number (countryCode + number separately)
        if (!contact.name || !validPhoneNumber.test(contact.countryCode + contact.number)) {
          hasInvalidContact = true;
          if (!firstInvalidSection) firstInvalidSection = contactRef;
          validationFailed = true;
        }
      }
    });
  
    newMissingFields.contacts = hasInvalidContact;
  
    // Validate medications, conditions, surgeries, allergies, etc.
    cleanedFormData.medications.forEach((medication) => {
      if ((medication.dosage || medication.frequency) && !medication.name) {
        newMissingFields.medications = true;
        if (!firstInvalidSection) firstInvalidSection = medicationRef;
        validationFailed = true;
      }
    });
  
    cleanedFormData.conditions.forEach((condition) => {
      if (!condition.trim()) {
        newMissingFields.conditions = true;
        if (!firstInvalidSection) firstInvalidSection = medicalRef;
        validationFailed = true;
      }
    });
  
    cleanedFormData.surgeries.forEach((surgery) => {
      if (!surgery.trim()) {
        newMissingFields.surgeries = true;
        if (!firstInvalidSection) firstInvalidSection = medicalRef;
        validationFailed = true;
      }
    });
  
    cleanedFormData.allergies.forEach((allergy) => {
      if (allergy.comments && !allergy.name) {
        newMissingFields.allergies = true;
        if (!firstInvalidSection) firstInvalidSection = allergyRef;
        validationFailed = true;
      }
    });
  
    if (cleanedFormData.dnr_status === 'Active' && (!fileUploaded || !cleanedFormData.keep_dnr_document)) {
      newMissingFields.dnr = true;
      if (!firstInvalidSection) firstInvalidSection = dnrRef;
      validationFailed = true;
    }
  
    // If validation fails, scroll to the first invalid section and return
    if (validationFailed) {
      setMissingFields(newMissingFields);
      if (firstInvalidSection) {
        firstInvalidSection.current.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    }
  
    // Prepare form data for submission
    const updatedFormData = new FormData();
    // Append fields only if they have a value in `formData`
    updatedFormData.append('name', formData.name || '');  // Empty string if name is cleared
    updatedFormData.append('date_of_birth', formData.date_of_birth || ''); 
    updatedFormData.append('nhs_id', formData.nhs_id || '');
    updatedFormData.append('insurance', formData.insurance || '');
    updatedFormData.append('blood_type', formData.blood_type || '');
    updatedFormData.append('last_updated', new Date().toISOString());
    // Append contacts with countryCode and number separately
    cleanedFormData.contacts.forEach((contact, index) => {
      updatedFormData.append(`contacts[${index}][name]`, contact.name || '');
      updatedFormData.append(`contacts[${index}][countryCode]`, contact.countryCode || '');  // Country code
      updatedFormData.append(`contacts[${index}][number]`, contact.number || '');  // Phone number (without concatenation)
      updatedFormData.append(`contacts[${index}][relationship]`, contact.relationship || '');
    });

    removedContacts.forEach((contact, index) => {
      updatedFormData.append(`removedContacts[${index}]`, contact.name || '');
    });
  
    // Append allergies
    cleanedFormData.allergies.forEach((allergy, index) => {
      updatedFormData.append(`allergies[${index}][name]`, allergy.name || '');
      updatedFormData.append(`allergies[${index}][comments]`, allergy.comments || '');
    });
  
    removedAllergies.forEach((allergy, index) => {
      updatedFormData.append(`removedAllergies[${index}]`, allergy.name || '');
    });

    updatedFormData.append('no_allergies', formData.no_allergies || false);
  
    // Append conditions
    cleanedFormData.conditions.forEach((condition, index) => {
      updatedFormData.append(`conditions[${index}]`, condition || '');
    });
  
    removedConditions.forEach((condition, index) => {
      updatedFormData.append(`removedConditions[${index}]`, condition || '');
    });
  
    // Append surgeries
    cleanedFormData.surgeries.forEach((surgery, index) => {
      updatedFormData.append(`surgeries[${index}]`, surgery || '');
    });
  
    removedSurgeries.forEach((surgery, index) => {
      updatedFormData.append(`removedSurgeries[${index}]`, surgery || '');
    });
  
    // Append medications
    cleanedFormData.medications.forEach((medication, index) => {
      updatedFormData.append(`medications[${index}][name]`, medication.name || '');
      updatedFormData.append(`medications[${index}][dosage]`, medication.dosage || '');
      updatedFormData.append(`medications[${index}][frequency]`, medication.frequency || '');
    });
  
    removedMedications.forEach((medication, index) => {
      updatedFormData.append(`removedMedications[${index}]`, medication.name || '');
    });

    updatedFormData.append('no_medications', formData.no_medications || false);

    updatedFormData.append('dnr_status', cleanedFormData.dnr_status || 'Inactive');
  
    // DNR document handling
    if (cleanedFormData.dnr_document && !cleanedFormData.remove_dnr_document) {
      updatedFormData.append('dnr_document', cleanedFormData.dnr_document);
    } else if (cleanedFormData.remove_dnr_document) {
      updatedFormData.append('remove_dnr_document', true);
    } else if (cleanedFormData.keep_dnr_document) {
      updatedFormData.append('keep_dnr_document', true);
    }

    console.log('Updated Form Data before submission:', [...updatedFormData.entries()]);

  
    try {
      const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}`, {
        // const response = await fetch(`http://localhost:5000/api/users/uuid/${uuid}`, {

        method: 'PUT',
        body: updatedFormData,
      });
  
      if (!response.ok) {
        throw new Error('Failed to update user');
      }
  
      navigate(`/user/${uuid}`);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };
  
  
  
  

  const handleReset = () => {
    setFormData(initialData);
  };

  return (
    <div className="container">
      <header>
        <div className="header-title">
          {/* <h2>Edit Emergency Medical Information</h2> */}
        </div>
      </header>
  
      <main>
        <form onSubmit={handleSubmit} onKeyDown={handleKeyDown} encType="multipart/form-data">
        <PersonalInfo
            formData={formData}
            setFormData={setFormData}
            personalRef={personalRef}
            setMissingFields={setMissingFields}
            missingFields={missingFields}
            submitted={submitted}
          />
          <Contact
            formData={formData}
            setFormData={setFormData}
            contactRef={contactRef}
            setMissingFields={setMissingFields}
            missingFields={missingFields}
            submitted={submitted}
            setRemovedContacts={setRemovedContacts}
          />
          <Medical formData={formData}
            setFormData={setFormData}
            setRemovedConditions={setRemovedConditions}  
            setRemovedSurgeries={setRemovedSurgeries}
          />
          <Medication
            formData={formData}
            setFormData={setFormData}
            medicationRef={medicationRef}
            missingFields={missingFields}            
            setMissingFields={setMissingFields}     
            setRemovedMedications={setRemovedMedications}  
            submitted={submitted}                 
          />

          <Allergy
            formData={formData}
            setFormData={setFormData}
            missingFields={missingFields}
            setMissingFields={setMissingFields}
            allergySectionRef={allergyRef}
            submitted={submitted}
            setRemovedAllergies={setRemovedAllergies}
          />          
          <DNR
            formData={formData}
            setFormData={setFormData}
            fileUploaded={fileUploaded}
            setFileUploaded={setFileUploaded}
            missingFields={missingFields}
            dnrRef={dnrRef}
            dnrDocumentUrl={dnrDocumentUrl}
          />
          <footer>
            <button type="button" className="reset-button" onClick={handleReset}>Reset Changes</button>
            <button type="submit" className="save-button">Save Changes</button>
          </footer>
        </form>
      </main>
    </div>
  );  
}

export default EditPage;
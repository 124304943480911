import React, {useState, useEffect} from 'react';

function Allergy({ formData, setFormData, missingFields, allergySectionRef, setRemovedAllergies }) {
  
const [selection, setSelection] = useState('');
  useEffect(() => {
    setSelection(formData.no_allergies ? 'I have no allergies' : 'I have allergies');

    if (!formData.no_allergies && formData.allergies.length === 0) {
      setFormData(prevFormData => ({
        ...prevFormData,
        allergies: [{ name: '', comments: ''}]  // Add a blank row
      }));
    }

  }, [formData.no_allergies, formData.allergies.length]);

  const handleSelectionChange = (event) => {
    const value = event.target.value;
    setSelection(value);

    if (value === 'I have no allergies') {
      // Update formData to reflect no allergies
      setFormData(prevFormData => ({
        ...prevFormData,
        no_allergies: true,
        allergies: []  // Clear all allergies
      }));
    } else if (value === 'I have allergies') {
      // Update formData to allow allergies
      setFormData(prevFormData => ({
        ...prevFormData,
        allergies: prevFormData.allergies.length === 0 
        ? [{ name: '', comments: '' }] 
        : prevFormData.allergies,
        no_allergies: false
      }));
    } else if (value === 'Select') {
      setFormData(prevFormData => ({
        ...prevFormData,
        no_allergies: false
      }));
    }
  };

  const handleAllergyChange = (e, index, field) => {
    const { value } = e.target;

    const updatedAllergies = formData.allergies.map((allergy, i) => {
      const updatedAllergy = allergy || { name: '', comments: '' };
      return i === index ? { ...updatedAllergy, [field]: value } : allergy;
    });

    setFormData({ ...formData, allergies: updatedAllergies });
  };

  const handleAddAllergy = () => {
    setFormData({
      ...formData,
      allergies: [...formData.allergies, { name: '', comments: '' }]
    });
  };

  const handleRemoveAllergy = (index) => {
    const allergyToRemove = formData.allergies[index];

    // Add removed allergy to the list if it has a name or any non-empty field
    if (allergyToRemove.name || allergyToRemove.comments) {
      setRemovedAllergies((prev) => [...prev, allergyToRemove]);
    }

    // Remove allergy from formData
    const updatedAllergies = formData.allergies.filter((_, i) => i !== index);
    setFormData({ ...formData, allergies: updatedAllergies });
  };



  return (
    <section
      className={`section ${missingFields.allergies ? 'highlight' : ''}`}  // Highlight section if there are missing fields
      ref={allergySectionRef}
    >
      <div className="section-content">
        <h2>Allergies</h2>

        <select value={selection} onChange={handleSelectionChange} className="allergy-select">
          {!selection && <option value="">Select</option>}
          <option value="I have allergies">I have allergies</option>
          <option value="I have no allergies">I have no allergies</option>
        </select>

        

        {missingFields.allergies && (
          <p className="error-message">Please provide a name for each allergy.</p>
        )}
        {!formData.no_allergies && (
        <table className="edit-table">
        <thead>
            <tr>
            <th>Allergy Name</th>
            <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {formData.allergies.map((allergy, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    value={allergy.name}
                    onChange={(e) => handleAllergyChange(e, index, 'name')}
                    placeholder="Allergy"
                  />
                </td>
                <td>
                  <textarea
                    value={allergy.comments}
                    onChange={(e) => handleAllergyChange(e, index, 'comments')}
                    placeholder="Comments"
                    rows="3"
                  />
                </td>
                <td>
                  <button
                    type="button"
                    className="remove-button"
                    onClick={() => handleRemoveAllergy(index)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        )}

        {selection === 'I have allergies' && (
          <button type="button" onClick={handleAddAllergy} className="add-button">
            Add Allergy
          </button>
        )}
     
      </div>
    </section>
  );
}

export default Allergy;

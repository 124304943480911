import React from 'react';

const CredentialsModal = ({
  email,
  setEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  termsAccepted,
  setTermsAccepted,
  error,
  onSubmit,
  onCancel,
  onShowTerms,
}) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Set Your Credentials</h2>
        <form onSubmit={onSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter a password"
            required
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm your password"
            required
          />
          <label>
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
              required
            />
            I agree to the&nbsp;
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onShowTerms();
              }}
            >
              terms & conditions
            </a>
          </label>
          {error && <p className="error-message">{error}</p>}
          <button type="submit">Set Credentials</button>
          <button type="button" onClick={onCancel}>
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};



export default CredentialsModal;

import React from 'react';

function Medical({ formData, setFormData, setRemovedConditions, setRemovedSurgeries }) {
  
  // Handle input changes for medical conditions/surgeries
  const handleMedicalInputChange = (index, e) => {
    const { name, value } = e.target;
  
    if (name === 'conditions' || name === 'surgeries') {
      const updatedList = [...formData[name]];
      updatedList[index] = value;
      setFormData({
        ...formData,
        [name]: updatedList,
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handle adding conditions/surgeries
  const handleAddMedical = (name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: [...prevFormData[name], ''], // Add a new blank entry
    }));
  };

  // Handle removal of conditions/surgeries
  const handleRemoveMedical = (name, index) => {
    const medicalToRemove = formData[name][index];

    // Track removed conditions or surgeries
    if (name === 'conditions' && medicalToRemove) {
      setRemovedConditions((prev) => [...prev, medicalToRemove]);
    } else if (name === 'surgeries' && medicalToRemove) {
      setRemovedSurgeries((prev) => [...prev, medicalToRemove]);
    }

    const updatedList = formData[name].filter((_, i) => i !== index);
    setFormData({
      ...formData,
      [name]: updatedList,
    });
  };

  return (
    <div>
    <section className="section">
      <div className="section-content">
        <h2>Medical Conditions</h2>
        {formData.conditions.map((condition, index) => (
          <div key={index} className="medical-item">
            <input
              type="text"
              name="conditions"
              value={condition}
              onChange={(event) => handleMedicalInputChange(index, event)}
              placeholder="Medical Condition"
            />
            <button type="button" onClick={() => handleRemoveMedical('conditions', index)}>
              Remove
            </button>
          </div>
        ))}
        <button type="button" onClick={() => handleAddMedical('conditions')} className="add-button">
          Add Medical Condition
        </button>
      </div>
    </section>

    <section className="section">
      <div className="section-content">
        <h2>Surgeries</h2>
        {formData.surgeries.map((surgery, index) => (
          <div key={index} className="medical-item">
            <input
              type="text"
              name="surgeries"
              value={surgery}
              onChange={(event) => handleMedicalInputChange(index, event)}
              placeholder="Surgery"
            />
            <button type="button" onClick={() => handleRemoveMedical('surgeries', index)}>
              Remove
            </button>
          </div>
        ))}
        <button type="button" onClick={() => handleAddMedical('surgeries')} className="add-button">
          Add Surgery
        </button>
      </div>
    </section>
    </div>
  );
}

export default Medical;

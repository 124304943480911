import React from 'react';
import TermsContent from './Terms'; // Import the terms content

const TermsModal = ({ onAccept, onCancel }) => (
  <div className="modal">
    <div className="modal-content terms-modal">
      <h2>Terms & Conditions</h2>
      <TermsContent /> {/* Render the content */}
      <button type="button" onClick={onAccept}>Accept</button>
      <button type="button" onClick={onCancel}>Cancel</button>
    </div>
  </div>
);

export default TermsModal;

import React from 'react';

const PasswordModal = ({ password, setPassword, error, onSubmit, onCancel, onForgotPassword }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Confirm Password</h2>
        <form onSubmit={onSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            required
          />
          {error && <p className="error-message">{error}</p>}
          <button type="submit">Confirm</button>
          <button type="button" onClick={onCancel}>
            Cancel
          </button>
          <p>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onForgotPassword();
              }}
            >
              Forgot Password?
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};



export default PasswordModal;

import React from 'react';

const PersonalInfo = ({ userData, calculateAge }) => {

  const isPersonalInfoEmpty = () => {
    return (
      !userData.date_of_birth &&
      !userData.nhs_id &&
      !userData.insurance &&
      !userData.blood_type
    );
  };


  return (
    <section className="section">
      <div className="section-content">
        <h1>Personal Information</h1>
        {isPersonalInfoEmpty() ? (
          <p>No personal information reported</p>
        ) : (
          <>
            {userData.date_of_birth && (
              <div className="label-value">
                <strong>Age:</strong>
                <span>{`${calculateAge(userData.date_of_birth)}`}</span>
              </div>
            )}
            {userData.nhs_id && (
              <div className="label-value">
                <strong>NHS ID:</strong>
                <span>{userData.nhs_id}</span>
              </div>
            )}
            {userData.insurance && (
              <div className="label-value">
                <strong>Insurance:</strong>
                <span>{userData.insurance}</span>
              </div>
            )}
            {userData.blood_type && (
              <div className="label-value">
                <strong>Blood Type:</strong>
                <span>{userData.blood_type}</span>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default PersonalInfo;

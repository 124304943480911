import React, { useState } from 'react';

function PersonalInfo({ formData, setFormData, personalRef, missingFields, setMissingFields, submitted }) { // Accept `submitted` prop
  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateDateOfBirth = () => {
    const today = new Date();
    const dob = new Date(formData.date_of_birth);
    let errors = { ...formErrors };

    if (!formData.date_of_birth || dob >= today) {
      errors.date_of_birth = 'Please enter a valid date of birth.';
      setFormErrors(errors);
      setMissingFields((prev) => ({
        ...prev,
        personalInfo: true, // Mark the personal info section as having missing fields
      }));
    } else {
      delete errors.date_of_birth;
      setFormErrors(errors);
      setMissingFields((prev) => ({
        ...prev,
        personalInfo: false, // Clear the missing fields for personal info
      }));
    }
  };

  // Validate on form submission
  React.useEffect(() => {
    if (submitted) {
      validateDateOfBirth();
    }
  }, [submitted]); // Trigger validation when `submitted` changes

  return (
    <section
      className={`section ${submitted && missingFields.personalInfo ? 'highlight' : ''}`} // Highlight only after submission
      ref={personalRef}
    >
      <div className="section-content">
        <h2>Personal Information</h2>
        {submitted && formErrors.date_of_birth && <p className="error-message">{formErrors.date_of_birth}</p>}
        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name || ''}
          onChange={handleInputChange}
        />
        <label>Date of Birth:</label>
        <input
          type="date"
          name="date_of_birth"
          value={formData.date_of_birth ? formData.date_of_birth.split('T')[0] : ''}
          onChange={handleInputChange}
        />
         {formData.country === 'UK' && (
          <>
            <label>NHS ID:</label>
            <input
              type="text"
              name="nhs_id"
              value={formData.nhs_id || ''}
              onChange={handleInputChange}
            />
          </>
        )}
        <label>Insurance Provider:</label>
        <input
          type="text"
          name="insurance"
          value={formData.insurance || ''}
          onChange={handleInputChange}
        />
        <label>Blood Type:</label>
        <select
          name="blood_type"
          value={formData.blood_type || ''}
          onChange={handleInputChange}
        >
          <option value="">Select Blood Type</option>
          <option value="A+">A+</option>
          <option value="A-">A-</option>
          <option value="B+">B+</option>
          <option value="B-">B-</option>
          <option value="O+">O+</option>
          <option value="O-">O-</option>
          <option value="AB+">AB+</option>
          <option value="AB-">AB-</option>
        </select>
      </div>
    </section>
  );
}

export default PersonalInfo;

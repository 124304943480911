import React from 'react';



const DNR = ({ dnrStatus, dnrDocument, uuid }) => {
  if (!dnrStatus || dnrStatus.toLowerCase() === 'inactive') {
    return null; // Do not render the component if DNR is inactive or not provided
  }

  return (
    <section className="section">
      <div className="section-content">
        <h1>Do Not Resuscitate (DNR) Status</h1>
        <p>
          <strong>Status: </strong>{dnrStatus || 'Not reported'}
        </p>

        {dnrDocument && dnrDocument.data && (
          <p>
            <a
              href={`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}/dnr`}
              className="view-dnr"
              target="_blank"
              rel="noopener noreferrer"
            >
              View DNR Document
            </a>
          </p>
        )}
      </div>
    </section>
  );
};

export default DNR;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginPage_login-page-wrapper__WZ40T {
    background-color: #ffffff; /* New background color */
    min-height: 100vh; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .LoginPage_container__2Ni1B {
    max-width: 600px;
    margin: 40px auto;
    padding: 30px;
    background-color: #ffffff;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Adjusted shadow */
    border-radius: 10px;
  }
`, "",{"version":3,"sources":["webpack://./src/LoginPage.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,yBAAyB;IACpD,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,yBAAyB;IACzB,yCAAyC,EAAE,oBAAoB;IAC/D,mBAAmB;EACrB","sourcesContent":[".login-page-wrapper {\n    background-color: #ffffff; /* New background color */\n    min-height: 100vh; \n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .container {\n    max-width: 600px;\n    margin: 40px auto;\n    padding: 30px;\n    background-color: #ffffff;\n    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Adjusted shadow */\n    border-radius: 10px;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login-page-wrapper": `LoginPage_login-page-wrapper__WZ40T`,
	"container": `LoginPage_container__2Ni1B`
};
export default ___CSS_LOADER_EXPORT___;

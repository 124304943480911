import React from 'react';

const ForgotPasswordModal = ({
  emailForRecovery,
  setEmailForRecovery,
  error,
  recoverySuccess,
  onSubmit,
  onCancel,
}) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Recover Password</h2>
        <form onSubmit={onSubmit}>
          <input
            type="email"
            value={emailForRecovery}
            onChange={(e) => setEmailForRecovery(e.target.value)}
            placeholder="Enter your email"
            required
          />
          {error && <p className="error-message">{error}</p>}
          {recoverySuccess && <p className="success-message">{recoverySuccess}</p>}
          <button type="submit">Send Recovery Email</button>
          <button type="button" onClick={onCancel}>
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};


export default ForgotPasswordModal;

import React from 'react';
import countryCodes from '../../countryCodes';

function Contact({ formData, setFormData, contactRef, missingFields, setRemovedContacts }) {

  // Handle contact changes
  const handleContactChange = (index, field, value) => {
    const updatedContacts = formData.contacts.map((contact, i) =>
      i === index ? { ...contact, [field]: value } : contact
    );
    setFormData({ ...formData, contacts: updatedContacts });
  };

  // Add a new contact with default countryCode
  const handleAddContact = () => {
    setFormData({
      ...formData,
      contacts: [...formData.contacts, { name: '', countryCode: '+1', number: '', relationship: '' }],
    });
  };

  // Remove contact and add to removed contacts if any field is non-empty
  const handleRemoveContact = (index) => {
    const contactToRemove = formData.contacts[index];

    // Add to removed contacts if any field is non-empty
    if (contactToRemove.name || contactToRemove.number || contactToRemove.relationship) {
      setRemovedContacts((prev) => [...prev, contactToRemove]);
    }

    // Remove the contact from formData
    const updatedContacts = formData.contacts.filter((_, i) => i !== index);
    setFormData({ ...formData, contacts: updatedContacts });
  };


  return (
    <section
      className={`section ${missingFields.contacts ? 'highlight' : ''}`}  // Highlight section if there are missing fields
      ref={contactRef}
    >
      <div className="section-content">
        <h2>Emergency Contacts</h2>
        {missingFields.contacts && (
          <p className="error-message">Please ensure each contact has a name and valid phone number.</p>
        )}
  
        {formData.contacts.map((contact, index) => (
          <div key={index} className="contact-item">
            <label>Contact {index + 1} Name:</label>
            <input
              type="text"
              name="name"
              value={contact.name}
              onChange={(e) => handleContactChange(index, 'name', e.target.value)}
              placeholder="Contact Name"
            />
  
            <label>Contact {index + 1} Country Code:</label>
            <select
              name="countryCode"
              value={contact.countryCode}  // Make sure `contact.countryCode` exists in the formData
              onChange={(e) => handleContactChange(index, 'countryCode', e.target.value)}
            >
              <option value="">Select Country Code</option>
              {countryCodes.map((country, i) => (
                <option key={i} value={country.code}>
                  {country.name} ({country.code})
                </option>
              ))}
            </select>
  
            <label>Contact {index + 1} Number:</label>
            <input
              type="text"
              name="number"
              value={contact.number}
              onChange={(e) => handleContactChange(index, 'number', e.target.value)}
              placeholder="Contact Number"
            />
  
            <label>Contact {index + 1} Relationship:</label>
            <input
              type="text"
              name="relationship"
              value={contact.relationship}
              onChange={(e) => handleContactChange(index, 'relationship', e.target.value)}
              placeholder="Relationship"
            />
  
            <button type="button" onClick={() => handleRemoveContact(index)}>
              Remove
            </button>
          </div>
        ))}
  
        <button type="button" onClick={handleAddContact} className='add-button'>
          Add Contact
        </button>
      </div>
    </section>
  );
}

export default Contact;

import React from 'react';
import PropTypes from 'prop-types';

const CountryModal = ({ showCountryModal, onCountrySelect, onClose }) => {
  if (!showCountryModal) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Are You a UK Resident?</h2>
        <button onClick={() => onCountrySelect(true)}>Yes, I am a UK Resident</button>
        <button onClick={() => onCountrySelect(false)}>No, I am not a UK Resident</button>
      </div>
    </div>
  );
};

CountryModal.propTypes = {
  showCountryModal: PropTypes.bool.isRequired,
  onCountrySelect: PropTypes.func.isRequired,
};

export default CountryModal;

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import EditPage from './components/EditPage/EditPage';
import UserPage from './components/UserPage';  // New component for dynamic user pages
import ResetPassword from './components/ResetPassword';

function App() {
  const [formData, setFormData] = useState({
    name: '',
    date_of_birth: '',
    blood_type: '',
    nhs_id: '',
    insurance:'',
    contacts: [{ name: '', number: '', countryCode:'' }],
    conditions: [''],  // Initialize conditions as an array with an empty string
    surgeries: [''],   // Initialize surgeries as an array with an empty string
    medications: [{ name: '', dosage: '', frequency: ''}],
    no_medications: false,
    allergies: [{name: '', comments: '', no_allergies: false}],
    no_allergies: false,
    dnr_status: 'Inactive',
    dnr_document: null,
    last_updated: 'Never',
    country: '',
  });
  

  return (
    <Routes>
      <Route path="/" element={<LoginPage formData={formData} />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/edit/:uuid" element={<EditPage formData={formData} setFormData={setFormData} />} />
      <Route path="/user/:uuid" element={<UserPage formData={formData} setFormData={setFormData} />} /> {/* Pass formData and setFormData */}
    </Routes>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';  
import '../styles.css';
import logo from '../assets/defaultProfile.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

// https://medicids-backend-34db21443628.herokuapp.com
function UserPage({ formData, setFormData })  {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { login } = useAuth();  
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasCredentials, setHasCredentials] = useState(false);
  const [password, setPassword] = useState('');
  const [emailForRecovery, setEmailForRecovery] = useState('');
  const [email, setEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(false);
  const [showSetCredentials, setShowSetCredentials] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showContactOptions, setShowContactOptions] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [recoverySuccess, setRecoverySuccess] = useState(null);
  const [showCountryModal, setShowCountryModal] = useState(false);

  const [loginAttempts, setLoginAttempts] = useState(0);
  const [lockoutTime, setLockoutTime] = useState(null);


  // Assigns class to body to apply background image without applying to all pages
  useEffect(() => {
    document.body.classList.add('background-image');

    // Clean up: Remove the class when the component unmounts
    return () => {
      document.body.classList.remove('background-image');
    };
  }, []);



  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}`);
        // const response = await fetch(`http://localhost:5000/api/users/uuid/${uuid}`);


        if (!response.ok) {
          throw new Error('User not found');
        }
        const data = await response.json();
        setUserData(data);

        if (data.email && data.password) {
          setHasCredentials(true);
        }
        // Show the country modal if the country value is missing
        if (!data.country) {
          setShowCountryModal(true);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [uuid]);

  // Check if credentials are missing
  useEffect(() => {
    if (userData && (!userData.email || !userData.password)) {
      setShowSetCredentials(true);
    }
  }, [userData]);

  // Lockout handling logic
  useEffect(() => {
    if (lockoutTime) {
      const remainingTime = Math.max(0, (lockoutTime - new Date()) / 1000);
      if (remainingTime <= 0) {
        setLockoutTime(null);
        setLoginAttempts(0);
      } else {
        const timeout = setTimeout(() => setLockoutTime(null), remainingTime * 1000);
        return () => clearTimeout(timeout);
      }
    }
  }, [lockoutTime]);

  const handleEditClick = () => {
    if (lockoutTime) {
      setError(`Too many failed attempts. Please try again in ${Math.ceil((lockoutTime - new Date()) / 1000 / 60)} minutes.`);
      return;
    }

    if (hasCredentials) {
      setShowPasswordPrompt(true);
    } else {
      setShowSetCredentials(true);
    }
  };

  const handleCountrySelection = async (isUK) => {
    console.log("handleCountrySelection triggered with value:", isUK); // Log the function call
    try {
      const updatedCountry = isUK ? 'UK' : 'Not UK';
      console.log("Updating country to:", updatedCountry);
  
      const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}/country`, {
      // const response = await fetch(`http://localhost:5000/api/users/uuid/${uuid}/country`, {

        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ country: updatedCountry }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update user country');
      }
  
      console.log("Country updated successfully in backend.");
  
      // Update local and central formData after successful update
      setUserData((prevData) => ({
        ...prevData,
        country: updatedCountry,
      }));
  
      setFormData((prevData) => ({
        ...prevData,
        country: updatedCountry,
      }));
  
      setShowCountryModal(false);
    } catch (error) {
      console.error('An error occurred while updating user country:', error);
      setError('An error occurred while updating user country.');
    }
  };
  
  
  
  

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setError(null); 

    if (loginAttempts >= 5) {
      setLockoutTime(new Date(new Date().getTime() + 10 * 60000));
      setError('Too many failed attempts. Please try again in 10 minutes.');
      return;
    }

    try {
      const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}/verify-password`, {
      // const response = await fetch(`http://localhost:5000/api/users/uuid/${uuid}/verify-password`, {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      });

      if (response.ok) {
        login();
        navigate(`/edit/${uuid}`);
      } else {
        const result = await response.json();
        setError(result.error || 'Invalid password');
        setLoginAttempts(prev => prev + 1);
      }
    } catch (err) {
      console.error(err);
      setError('An error occurred while verifying the password.');
    }
  };

  const handleSetCredentialsSubmit = async (e) => {
    e.preventDefault();
    setError(null); 

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setError('Invalid email format');
      return;
    }

    if (!termsAccepted) {
      setError('You must accept the terms and conditions');
      return;
    }

    try {
      const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}/set-credentials`, {
      // const response = await fetch(`http://localhost:5000/api/users/uuid/${uuid}/set-credentials`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, termsAccepted }),
      });

      if (response.ok) {
        setUserData({ ...userData, email, password });
        login();
        navigate(`/edit/${uuid}`);
      } else {
        const result = await response.json();
        setError(result.error || 'Failed to set credentials');
      }
    } catch (err) {
      console.error(err);
      setError('An error occurred while setting credentials.');
    }
  };

  const handleAcceptTerms = () => {
    setTermsAccepted(true);
    setShowTermsModal(false);
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError(null); 
    setRecoverySuccess(null); 

    try {
      const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/password-recovery`, {
      //  const response = await fetch(`http://localhost:5000/api/users/password-recovery`, {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: emailForRecovery }),
      });

      if (response.ok) {
        setRecoverySuccess('Recovery email sent successfully.');
        setShowForgotPassword(false);
        setShowPasswordPrompt(false);
      } else {
        const result = await response.json();
        setError(result.error || 'Failed to send recovery email.');
      }
    } catch (err) {
      console.error(err);
      setError('An error occurred while trying to send the recovery email.');
    }
  };

  const calculateAge = (dateOfBirth) => {
    if (!dateOfBirth) return null;

    const dob = new Date(dateOfBirth);
    const now = new Date();
    
    let age = now.getFullYear() - dob.getFullYear();
    let months = now.getMonth() - dob.getMonth();

    if (months < 0 || (months === 0 && now.getDate() < dob.getDate())) {
      age--;
      months += 12;
    }

    if (age >= 18) {
      return `${age} years`;
    } else {
      return `${age} years & ${months} months`;
    }
  };

  const handleCallClick = () => {
    if (!userData.contacts || userData.contacts.length === 0) {
      alert('No emergency contacts available.');
      return;
    }
  
    // Check if the device is a mobile device
    if (!navigator.userAgent.match(/Mobile|iPhone|Android|BlackBerry|IEMobile|Opera Mini/i)) {
      alert('Calling is only available on mobile devices.');
      return;
    }
  
    const formatPhoneNumber = (contact) => {
      return `${contact.countryCode}${contact.number}`;  // Concatenate country code and number
    };
  
    if (userData.contacts.length === 1) {
      // Call the single contact with country code
      makeCall(formatPhoneNumber(userData.contacts[0]));
    } else {
      // Show options if there are multiple contacts
      setShowContactOptions(true);
    }
  };
  

  const makeCall = (number) => {
    let anonymousNumber = number;
  
    // Add anonymous prefix based on country code
    if (number.startsWith('+1')) {
      // US or Canada
      anonymousNumber = `*67${number}`;
    } else if (number.startsWith('+44')) {
      // UK
      anonymousNumber = `141${number}`;
    }
  
    // Initiate the call with the modified number for anonymous calling
    window.location.href = `tel:${anonymousNumber}`;
  };

  const handleContactSelection = (number) => {
    makeCall(number);
    setShowContactOptions(false);
  };

  // Function to close modals and reset error state and input fields
  const closePasswordPrompt = () => {
    setShowPasswordPrompt(false);
    setError(null); // Reset error state
    setPassword(''); // Clear the password input field
  };

  const closeSetCredentials = () => {
    setShowSetCredentials(false);
    setError(null); // Reset error state
    setEmail(''); // Clear the email input field
    setPassword(''); // Clear the password input field
    setConfirmPassword(''); // Clear the confirm password input field
    setTermsAccepted(false); // Reset the terms accepted checkbox
  };

  const closeForgotPassword = () => {
    setShowForgotPassword(false);
    setError(null); // Reset error state
    setEmailForRecovery(''); // Clear the email for recovery input field
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  function formatPhoneNumber(countryCode, number) {
    if (countryCode === '+1' && number.length === 10) {
      // Format number in +1-XXX-XXX-XXXX
      return `${countryCode}-${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6)}`;
    }
    // Return as is if not +1 or not 10 digits
    return `${countryCode}${number}`;
  }
  
  return (
    <div className="container">
      <header>
        <div className="header-title">
          {/* Circular Profile Image */}
          <div className="image-container">
            <img src={logo} alt="Profile" className="profile-image" />
          </div>
          {userData.name && <h1>{userData.name || ''}</h1>}
          {userData.conditions && userData.conditions.length > 0 && (
            <p className="first-condition">{userData.conditions[0]}</p>
          )}
        </div>
      </header>


      <main>
      <section className="section">
        <div className="section-content">
          <h1>Personal Information</h1>
          {userData.date_of_birth && (
            <div className="label-value">
              <strong>Age:</strong>
              <span>{`${calculateAge(userData.date_of_birth)}`}</span>
            </div>
          )}
          {userData.nhs_id && (
            <div className="label-value">
              <strong>NHS ID:</strong>
              <span>{userData.nhs_id}</span>
            </div>
          )}
          {userData.insurance && (
            <div className="label-value">
              <strong>Insurance:</strong>
              <span>{userData.insurance}</span>
            </div>
          )}
          {userData.blood_type && (
            <div className="label-value">
              <strong>Blood Type:</strong>
              <span>{userData.blood_type}</span>
            </div>
          )}
        </div>
      </section>

        
        <section className="section">
          <div className="section-content">
            <h1>Emergency Contacts</h1>
            {userData.contacts.length > 0 ? (
              <table className="user-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Number</th>
                    <th>Relationship</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.contacts.map((contact, index) => (
                    <tr key={index}>
                      <td data-label="Name">{contact.name}</td>
                      <td data-label="Number">{formatPhoneNumber(contact.countryCode, contact.number)}</td>
                      <td data-label="Relationship">{contact.relationship}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No emergency contacts provided.</p>
            )}
          </div>
        </section>

        
        {/* Medical Conditions Section */}
        <section className="section">
          <div className="section-content">
            <h1>Medical Conditions</h1>
            <div className="conditions-list">
              {userData.conditions.map((condition, index) => (
                <h3 key={index} className="condition-item">{condition}</h3>
              ))}
            </div>
          </div>
        </section>

        {/* Surgeries Section */}
        <section className="section">
          <div className="section-content">
            <h1>Surgeries</h1>
            {Array.isArray(userData.surgeries) && userData.surgeries.filter(surgery => surgery && surgery.trim()).length > 0 ? (
              <div className="conditions-list">
                {userData.surgeries.filter(surgery => surgery && surgery.trim()).map((surgery, index) => (
                  <h3 key={index} className="condition-item">{surgery}</h3>
                ))}
              </div>
            ) : (
              <p>No surgeries reported</p>
            )}
          </div>
        </section>







        
        <section className="section">
          <div className="section-content">
            <h1>Current Medications</h1>
            {userData.no_medications ? (
              <p>Not currently taking any medication.</p>
            ) :
            Array.isArray(userData.medications) && userData.medications.filter(medication => medication.name || medication.dosage || medication.frequency).length > 0 ? (
              <table className="user-table">
                <thead>
                  <tr>
                    <th>Medication</th>
                    <th>Dosage</th>
                    <th>Frequency</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.medications.map((medication, index) => (
                    <tr key={index}>
                      <td data-label="Medication">{medication.name}</td>
                      <td data-label="Dosage">{medication.dosage}</td>
                      <td data-label="Frequency">{medication.frequency}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No medications reported.</p>
            )}
          </div>
        </section>

        <section className="section">
          <div className="section-content">
            <h1>Allergies</h1>
            
            {/* Check if no_allergies is true */}
            {userData.no_allergies ? (
              <p>No known allergies</p>
            ) : (
              userData.allergies && userData.allergies.filter(allergy => allergy.name).length > 0 ? (
                <table className="user-table">
                  <thead>
                    <tr>
                      <th>Allergy</th>
                      <th>Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData.allergies
                      .filter(allergy => allergy.name)
                      .map((allergy, index) => (
                        <tr key={index}>
                          <td data-label="Allergy">{allergy.name}</td>
                          <td data-label="Comments">
                            {allergy.comments || 'No comments'}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <p>No allergies reported.</p>
              )
            )}
          </div>
        </section>



        {userData.dnr_status && userData.dnr_status.toLowerCase() !== 'inactive' && (
          <section className="section">
            <div className="section-content">
              <h1>Do Not Resuscitate (DNR) Status</h1>
              <p><strong>Status: </strong>{userData.dnr_status || 'Not reported'}</p>

              {userData.dnr_document && userData.dnr_document.data && (
                <p>
                    <a href={`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}/dnr`} className="view-dnr" target="_blank" rel="noopener noreferrer">

                    {/* <a href={`http://localhost:5000/api/users/uuid/${uuid}/dnr`} className="view-dnr" target="_blank" rel="noopener noreferrer"> */}
                    View DNR Document
                  </a>
                </p>
              )}
            </div>
          </section>
        )}
      </main>  

      <footer>
        <p>Last Updated: <span>{userData.last_updated === "Never" || !userData.last_updated ? "Never" : new Date(userData.last_updated).toLocaleDateString()}</span></p>
        <button type="button" className="call-button" onClick={handleCallClick}>Call Emergency Contact</button>
        <button type="button" className="edit-button" onClick={handleEditClick}>Edit  <FontAwesomeIcon icon={faPenToSquare} /></button>
      </footer>

      {showContactOptions && (
        <div className="modal">
          <div className="modal-content">
            <h2>Select a Contact</h2>
            {userData.contacts.map((contact, index) => (
              <button
                key={index}
                className="contact-selection-button"
                onClick={() => handleContactSelection(`${contact.countryCode}${contact.number}`)}  // Concatenate countryCode and number
              >
                {contact.name}: {formatPhoneNumber(contact.countryCode, contact.number)}  {/* Display countryCode and number */}
              </button>
            ))}
            <button className="cancel-button" onClick={() => setShowContactOptions(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}


      {showPasswordPrompt && (
        <div className="modal">
          <div className="modal-content">
            <h2>Confirm Password</h2>
            <form onSubmit={handlePasswordSubmit}>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
              />
              {error && <p className="error-message">{error}</p>}
              <button type="submit">Confirm</button>
              <button type="button" onClick={closePasswordPrompt}>Cancel</button>
              <p><a href="#" onClick={() => { closePasswordPrompt(); setShowForgotPassword(true); }}>Forgot Password?</a></p>
            </form>
          </div>
        </div>
      )}

      {showForgotPassword && (
        <div className="modal">
          <div className="modal-content">
            <h2>Recover Password</h2>
            <form onSubmit={handleForgotPassword}>
              <input
                type="email"
                value={emailForRecovery}
                onChange={(e) => setEmailForRecovery(e.target.value)}
                placeholder="Enter your email"
                required
              />
              {error && <p className="error-message">{error}</p>}
              {recoverySuccess && <p className="success-message">{recoverySuccess}</p>}
              <button type="submit">Send Recovery Email</button>
              <button type="button" onClick={closeForgotPassword}>Cancel</button>
            </form>
          </div>
        </div>
      )}

      {showSetCredentials && (
        <div className="modal">
          <div className="modal-content">
            <h2>Set Your Credentials</h2>
            <form onSubmit={handleSetCredentialsSubmit}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter a password"
                required
              />
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm your password"
                required
              />
              <label>
                <input
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                  required
                />
                I agree to the&nbsp; <a href="#" onClick={(e) => { e.preventDefault(); setShowTermsModal(true); }}>terms & conditions</a>
              </label>
              {error && <p className="error-message">{error}</p>}
              <button type="submit">Set Credentials</button>
              <button type="button" onClick={closeSetCredentials}>Cancel</button>
            </form>
          </div>
        </div>
      )}

      {showTermsModal && (
        <div className="modal">
          <div className="modal-content terms-modal">
            <h2>Terms & Conditions</h2>
            <div className="terms-content">
              <p>By creating an account on our web application, you agree to comply with and be bound by the following Terms & Conditions. Please read them carefully before using the service.</p>
              <h2>User Responsibility</h2>
              <p>As a user, you are solely responsible for ensuring the accuracy and completeness of the personal health information you provide within your account. It is your duty to update your information as necessary to maintain its accuracy. We do not verify the information you submit and are not liable for any consequences arising from outdated, incomplete, or inaccurate data.</p>
              <h2>Liability Disclaimer</h2>
              <p>We do not accept any liability for damages, losses, or injuries resulting from the reliance on or use of the information provided in your personal health record. The information in your account is self-reported, and any medical decisions made based on this information are at your own risk. Always consult with a qualified healthcare professional before making any health-related decisions.</p>
              <h2>Account Security</h2>
              <p>You are responsible for maintaining the confidentiality of your account credentials, including your password. You agree to notify us immediately if you suspect any unauthorized use of your account. We are not liable for any loss or damage arising from your failure to protect your account information.</p>
              <h2>Use of Services</h2>
              <p>The services provided by our web application are intended for personal use only. You agree not to use the application for any unlawful or prohibited activities, including but not limited to the misuse of the information stored within the application.</p>
              <h2>Privacy and Data Protection</h2>
              <p>We are committed to protecting your privacy and handling your personal information in accordance with our Privacy Policy. By using our service, you consent to the collection, storage, and processing of your data as outlined in our Privacy Policy.</p>
              <h2>Modifications to the Terms</h2>
              <p>We reserve the right to update or modify these Terms & Conditions at any time without prior notice. Your continued use of the service following any changes signifies your acceptance of the updated terms. We encourage you to review the Terms & Conditions periodically.</p>
              <h2>Termination</h2>
              <p>We reserve the right to suspend or terminate your account at any time, with or without cause, including but not limited to any violation of these Terms & Conditions. Upon termination, your access to the service will be revoked, and any data associated with your account may be deleted.</p>
              <h2>Governing Law</h2>
              <p>These Terms & Conditions are governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law principles. Any disputes arising under or in connection with these terms shall be resolved in the courts of the United Kingdom.</p>
            </div>
            <button type="button" onClick={handleAcceptTerms}>Accept</button>
            <button type="button" onClick={() => setShowTermsModal(false)}>Cancel</button>
          </div>
        </div>
      )}

      {showCountryModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Are You a UK Resident?</h2>
            <button onClick={() => handleCountrySelection(true)}>Yes, I am a UK Resident</button>
            <button onClick={() => handleCountrySelection(false)}>No, I am not a UK Resident</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserPage;

import React, { useEffect } from 'react';

function DNRSection({ formData, setFormData, fileUploaded, setFileUploaded, missingFields, dnrRef, dnrDocumentUrl }) {
  const { uuid } = formData;

  // Handles file upload changes and immediately uploads to the backend
  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      // Check both file extension and MIME type to ensure file names can't just be changed and submitted with a valid suffix even if type is invalid
      const validTypes = [
        "application/pdf", // PDF
        "application/msword", // DOC
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
        "application/rtf", // RTF
        "image/jpeg", // JPEG, JPG
        "image/png", // PNG
      ];
      const validExtensions = [".pdf", ".doc", ".docx", ".rtf", ".jpeg", ".jpg", ".png"];

        // Check if the file type is valid based on MIME type
        if (!validTypes.includes(file.type)) {
          alert("Invalid file type. Please upload a PDF, DOC, DOCX, RTF, JPEG, or PNG file.");
          event.target.value = ""; // Clear the input
          return;
        }

        // Optionally, further check if the file extension is valid (backup check)
        const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          alert("Invalid file extension. Please ensure the file is in an acceptable format (.pdf, .doc, .docx, .rtf, .jpeg, .jpg, or .png).");
          event.target.value = ""; // Clear the input
          return;
        }


      // Create form data to send the file
      const formData = new FormData();
      formData.append('dnr_document', file);

      try {
        // API call to upload the DNR document immediately
        const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}/dnr`, {
        // const response = await fetch(`http://localhost:5000/api/users/uuid/${uuid}/dnr`, {

          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to upload DNR document');
        }

        // Update the frontend state to reflect the successful upload
        setFormData((prevFormData) => ({
          ...prevFormData,
          dnr_document: file,
          dnr_status: 'Active',
          remove_dnr_document: false,
          keep_dnr_document: true,
        }));
        setFileUploaded(true);
        console.log('DNR document uploaded successfully');
      } catch (error) {
        console.error('Error uploading DNR document:', error);
        // Optionally, you could add error messaging to inform the user
      }
    }
  };

  // Handles removal of an existing file by calling the API
  // Handles removal of an existing file by calling the API
  const handleRemoveFile = async () => {
    try {
      const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}/dnr`, {

      // const response = await fetch(`http://localhost:5000/api/users/uuid/${uuid}/dnr`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to remove DNR document');
      }

      // Update the frontend state to reflect the removal and set status to "Inactive"
      setFormData((prevFormData) => ({
        ...prevFormData,
        dnr_document: null,
        dnr_status: 'Inactive',    // Revert status to Inactive
        remove_dnr_document: true, // Mark that the document should be removed
        keep_dnr_document: false,
      }));
      setFileUploaded(false);
      console.log('DNR document removed successfully');
    } catch (error) {
      console.error('Error removing DNR document:', error);
    }
  };

  // Handles changes to the DNR status (Active/Inactive)
  const handleDnrStatusChange = (e) => {
    const value = e.target.value;
    if (value === 'Inactive') {
      // When switching to inactive, remove the document and reset fileUploaded
      setFormData((prevFormData) => ({
        ...prevFormData,
        dnr_status: value,
        dnr_document: null, // Ensure no document is associated when inactive
        remove_dnr_document: true,
        keep_dnr_document: false,
      }));
      setFileUploaded(false); // Reset fileUploaded status
    } else if (value === 'Active') {
      // When switching back to active, require a new document if none exists
      setFormData((prevFormData) => ({
        ...prevFormData,
        dnr_status: value,
        keep_dnr_document: formData.dnr_document ? true : false,
      }));
      // Ensure fileUploaded is false if no document is already uploaded
      if (!formData.dnr_document) {
        setFileUploaded(false);
      }
    }
  };

  // Set initial state for keeping the document if already present
  useEffect(() => {
    if (formData.dnr_document && formData.dnr_document.data) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        keep_dnr_document: formData.dnr_status === 'Active',
      }));
    }
  }, [formData.dnr_document, setFormData]);

  return (
    <section className={`section ${missingFields.dnr ? 'highlight' : ''}`} ref={dnrRef}>
      <div className="section-content">
        <h2>Do Not Resuscitate (DNR) Status</h2>
        {missingFields.dnr && (
          <p className="error-message">Please upload a DNR document if the status is Active.</p>
        )}

        {/* Dropdown for setting DNR status */}
        <select
          name="dnr_status"
          value={formData.dnr_status || 'Inactive'}
          onChange={handleDnrStatusChange}
        >
          <option value="Inactive">Inactive</option>
          <option value="Active">Active</option>
        </select>

        {formData.dnr_status === 'Active' && (
          <>
            {/* If no document is uploaded, show the file input for uploading */}
            {(!dnrDocumentUrl || !fileUploaded) && (
              <>
                <label>Upload DNR Document:</label>
                <input
                  type="file"
                  name="dnr_document"
                  onChange={handleFileChange}
                  disabled={formData.dnr_status !== 'Active'}
                  accept=".pdf,.doc,.docx,.rtf,.jpeg,.jpg,.png"
                />
              </>
            )}

            {/* Display link to view the uploaded document if it is present */}
            {fileUploaded && (
              <div>
                <p
                style={{textAlign: 'left'}}>Current DNR Document:</p>
                <a
                  href={`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}/dnr`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="view-dnr"
                >
                  View DNR Document
                </a>
              </div>
            )}

            {/* Show the remove button if a document is uploaded */}
            {fileUploaded && (
              <div>
                <button type="button" onClick={handleRemoveFile} style={{ backgroundColor: '#dc3545', color: 'white' }}>
                  Remove File
                </button>
              </div>
            )}
          </>
        )}

        {/* Hidden input to track if the DNR document should be kept */}
        <input type="hidden" name="keep_dnr_document" value={formData.keep_dnr_document ? 'true' : 'false'} />
      </div>
    </section>
  );
}

export default DNRSection;

import '../styles.css';
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function ResetPassword() {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/reset-password/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      });

      if (!response.ok) {
        const { error } = await response.json();
        throw new Error(error || 'Failed to reset password');
      }

      setSuccess(true);
      setError('');

      // Get the user UUID from the response if it is returned
      const { uuid } = await response.json();

      // Redirect to the user page after 2 seconds
      setTimeout(() => {
        navigate(`/user/${uuid}`);
      }, 2000);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="container">
      <h2>Reset Your Password</h2>
      {error && <p className="error-message">{error}</p>}
      {success ? (
        <p>Password has been reset successfully. Redirecting to your profile...</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="section-content">
          <label>
            New Password:
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>

          <label>
            Confirm New Password:
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </label>
          <button type="submit">Reset Password</button>
          </div>
        </form>
      )}
    </div>
  );
}

export default ResetPassword;

import React from 'react';



const Allergies = ({ noAllergies, allergies }) => {
  const filteredAllergies = Array.isArray(allergies)
    ? allergies.filter(allergy => allergy.name)
    : [];

  return (
    <section className="section">
      <div className="section-content">
        <h1>Allergies</h1>
        {noAllergies ? (
          <p>No known allergies</p>
        ) : filteredAllergies.length > 0 ? (
          <table className="user-table">
            <thead>
              <tr>
                <th>Allergy</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              {filteredAllergies.map((allergy, index) => (
                <tr key={index}>
                  <td data-label="Allergy">{allergy.name}</td>
                  <td data-label="Comments">
                    {allergy.comments || 'No comments'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No allergies reported.</p>
        )}
      </div>
    </section>
  );
};

export default Allergies;

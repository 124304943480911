import React from 'react';

const TermsContent = () => (
  <div className="terms-content">
    <p>
      By creating an account on our web application, you agree to comply with and be bound by the following Terms & Conditions. Please read them carefully before using the service.
    </p>
    <h2>User Responsibility</h2>
    <p>
      As a user, you are solely responsible for ensuring the accuracy and completeness of the personal health information you provide within your account. It is your duty to update your information as necessary to maintain its accuracy. We do not verify the information you submit and are not liable for any consequences arising from outdated, incomplete, or inaccurate data.
    </p>
    <h2>Liability Disclaimer</h2>
    <p>
      We do not accept any liability for damages, losses, or injuries resulting from the reliance on or use of the information provided in your personal health record. The information in your account is self-reported, and any medical decisions made based on this information are at your own risk. Always consult with a qualified healthcare professional before making any health-related decisions.
    </p>
    <h2>Account Security</h2>
    <p>
      You are responsible for maintaining the confidentiality of your account credentials, including your password. You agree to notify us immediately if you suspect any unauthorized use of your account. We are not liable for any loss or damage arising from your failure to protect your account information.
    </p>
    <h2>Use of Services</h2>
    <p>
      The services provided by our web application are intended for personal use only. You agree not to use the application for any unlawful or prohibited activities, including but not limited to the misuse of the information stored within the application.
    </p>
    <h2>Privacy and Data Protection</h2>
    <p>
      We are committed to protecting your privacy and handling your personal information in accordance with our Privacy Policy. By using our service, you consent to the collection, storage, and processing of your data as outlined in our Privacy Policy.
    </p>
    <h2>Modifications to the Terms</h2>
    <p>
      We reserve the right to update or modify these Terms & Conditions at any time without prior notice. Your continued use of the service following any changes signifies your acceptance of the updated terms. We encourage you to review the Terms & Conditions periodically.
    </p>
    <h2>Termination</h2>
    <p>
      We reserve the right to suspend or terminate your account at any time, with or without cause, including but not limited to any violation of these Terms & Conditions. Upon termination, your access to the service will be revoked, and any data associated with your account may be deleted.
    </p>
    <h2>Governing Law</h2>
    <p>
      These Terms & Conditions are governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law principles. Any disputes arising under or in connection with these terms shall be resolved in the courts of the United Kingdom.
    </p>
  </div>
);

export default TermsContent;

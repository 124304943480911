import React, { useState, useEffect } from 'react';
import { FaUpload } from 'react-icons/fa';
import logo from '../../assets/defaultProfile.jpg';


function PersonalInfo({ formData, setFormData, personalRef, missingFields, setMissingFields, submitted, setProfilePictureFile, }) { 
  const [formErrors, setFormErrors] = useState({});
  const [profilePicturePreview, setProfilePicturePreview] = useState(null);
  useEffect(() => {
    // Initialize profile picture from formData if it exists
    if (formData.profile_pic) {
      if (typeof formData.profile_pic === 'string') {
        setProfilePicturePreview(formData.profile_pic); // Assume it's a URL
      } else if (formData.profile_pic instanceof File) {
        const reader = new FileReader();
        reader.onload = () => {
          setProfilePicturePreview(reader.result);
        };
        reader.readAsDataURL(formData.profile_pic);
      }
    }
  }, [formData.profile_pic]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePicturePreview(reader.result); // Update preview
      };
      reader.readAsDataURL(file);
      setProfilePictureFile(file); // Pass the file to the parent
    }
  };

  const validateDateOfBirth = () => {
    const today = new Date();
    const dob = new Date(formData.date_of_birth);
    let errors = { ...formErrors };

    if (!formData.date_of_birth || dob >= today) {
      errors.date_of_birth = 'Please enter a valid date of birth.';
      setFormErrors(errors);
      setMissingFields((prev) => ({
        ...prev,
        personalInfo: true, // Mark the personal info section as having missing fields
      }));
    } else {
      delete errors.date_of_birth;
      setFormErrors(errors);
      setMissingFields((prev) => ({
        ...prev,
        personalInfo: false, // Clear the missing fields for personal info
      }));
    }
  };

  // Validate on form submission
  React.useEffect(() => {
    if (submitted) {
      validateDateOfBirth();
    }
  }, [submitted]); // Trigger validation when `submitted` changes

  return (
    <div>
      <div>
      <section className="image-section"
      >
       <div className="image-upload-container">
        <div className="image-container">
          <img
            src={
              profilePicturePreview || 
              (formData.profile_pic
                ? `https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${formData.uuid}/profile-pic`
                : logo) // Default logo if no picture exists
            }
            alt="Profile Preview"
            className="profile-image"
          />
        </div>
        <label htmlFor="profile-pic-upload" className="upload-button">
          <FaUpload className="upload-icon" />
          Upload
        </label>
        <input
          id="profile-pic-upload"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: 'none' }} // Hide default file input
        />
      </div>
    </section>

    </div>
      <div>
      <section
      className={`section ${submitted && missingFields.personalInfo ? 'highlight' : ''}`} // Highlight only after submission
      ref={personalRef}
    >
      <div className="section-content">
        <h2>Personal Information</h2>

        {submitted && formErrors.date_of_birth && <p className="error-message">{formErrors.date_of_birth}</p>}
        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name || ''}
          onChange={handleInputChange}
        />
        <label>Date of Birth:</label>
        <input
          type="date"
          name="date_of_birth"
          value={formData.date_of_birth ? formData.date_of_birth.split('T')[0] : ''}
          onChange={handleInputChange}
        />
         {formData.country === 'UK' && (
          <>
            <label>NHS ID:</label>
            <input
              type="text"
              name="nhs_id"
              value={formData.nhs_id || ''}
              onChange={handleInputChange}
            />
          </>
        )}
        <label>Insurance Provider:</label>
        <input
          type="text"
          name="insurance"
          value={formData.insurance || ''}
          onChange={handleInputChange}
        />
        <label>Blood Type:</label>
        <select
          name="blood_type"
          value={formData.blood_type || ''}
          onChange={handleInputChange}
        >
          <option value="">Select Blood Type</option>
          <option value="A+">A+</option>
          <option value="A-">A-</option>
          <option value="B+">B+</option>
          <option value="B-">B-</option>
          <option value="O+">O+</option>
          <option value="O-">O-</option>
          <option value="AB+">AB+</option>
          <option value="AB-">AB-</option>
        </select>
      </div>
    </section>
    </div>
    </div>
  );
}

export default PersonalInfo;

import React from 'react';


const Contacts = ({ contacts, formatPhoneNumber }) => {
  return (
    <section className="section">
      <div className="section-content">
        <h1>Emergency Contacts</h1>
        {contacts && contacts.length > 0 ? (
          <table className="user-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Number</th>
                <th>Relationship</th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact, index) => (
                <tr key={index}>
                  <td data-label="Name">{contact.name}</td>
                  <td data-label="Number">{formatPhoneNumber(contact.countryCode, contact.number)}</td>
                  <td data-label="Relationship">{contact.relationship}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No emergency contacts reported.</p>
        )}
      </div>
    </section>
  );
};

export default Contacts;

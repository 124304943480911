import React from 'react';


const Medications = ({ noMedications, medications }) => {
  const filteredMedications = Array.isArray(medications)
    ? medications.filter(medication => medication.name || medication.dosage || medication.frequency)
    : [];

  return (
    <section className="section">
      <div className="section-content">
        <h1>Current Medications</h1>
        {noMedications ? (
          <p>Not currently taking any medication.</p>
        ) : filteredMedications.length > 0 ? (
          <table className="user-table">
            <thead>
              <tr>
                <th>Medication</th>
                <th>Dosage</th>
                <th>Frequency</th>
              </tr>
            </thead>
            <tbody>
              {filteredMedications.map((medication, index) => (
                <tr key={index}>
                  <td data-label="Medication">{medication.name}</td>
                  <td data-label="Dosage">{medication.dosage}</td>
                  <td data-label="Frequency">{medication.frequency}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No medications reported.</p>
        )}
      </div>
    </section>
  );
};

export default Medications;

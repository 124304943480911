import React, { useState, useEffect } from 'react';

function Medication({ formData, setFormData, medicationRef, missingFields, setMissingFields, setRemovedMedications, submitted }) {
  
  // Check if formData and medications exist before proceeding
  if (!formData || !formData.medications) {
    return null; // Return null if formData or medications is not ready yet
  }



  const medications = formData.medications;

  const [selection, setSelection] = useState('');

  useEffect(() => {
    setSelection(formData.no_medications ? 'I am not on medications' : 'I am on medication');

    if (!formData.no_medications && formData.medications.length === 0) {
      setFormData(prevFormData => ({
        ...prevFormData,
        medications: [{ name: '', dosage: '', frequency: '' }]  // Add a blank row
      }));
    }
  }, 
  [formData.no_medications, formData.medications.length]);

  const handleSelectionChange = (event) => {
    const value = event.target.value;
    setSelection(value);

    if (value === 'I am not on medications') {
      // Update formData to reflect no medications
      setFormData(prevFormData => ({
        ...prevFormData,
        no_medications: true,
        medications: []  // Clear all medications
      }));
    } else if (value === 'I am on medication') {
      // Update formData to allow medications
      setFormData(prevFormData => ({
        ...prevFormData,
        medications: prevFormData.medications.length === 0 
        ? [{ name: '', dosage: '', frequency: '' }] 
        : prevFormData.medications,
        no_medications: false
      }));
    } else if (value === 'Select') {
      setFormData(prevFormData => ({
        ...prevFormData,
        no_medications: false
      }))
    }};


  const handleMedicationChange = (e, index, field) => {
    const { value } = e.target;
    const updatedMedications = medications.map((med, i) =>
      i === index ? { ...med, [field]: value } : med
    );
    setFormData({ ...formData, medications: updatedMedications, no_medications:false });
  };

  const handleAddMedication = () => {
    setFormData({
      ...formData,
      medications: [...medications, { name: "", dosage: "", frequency: "" }]
    });
  };

  const handleRemoveMedication = (index) => {
    const updatedMedications = [...formData.medications];
    const medicationToRemove = updatedMedications[index];
  
    // Log the medication being removed
    console.log('Removing medication:', medicationToRemove);
  
    // Add to the removedMedications array
    setRemovedMedications((prev) => [...prev, medicationToRemove]);
  
    // Remove the medication from the medications array
    updatedMedications.splice(index, 1);
  
    // Update formData state, setting medications to an empty array if no items are left
    setFormData({ ...formData, medications: updatedMedications.length ? updatedMedications : [] });
  
    // Log the updated medications array
    console.log('Updated Medications after removal:', updatedMedications);
  };
  

  // Validate medications to check if any medication is missing a name
  const validateMedications = () => {
    let hasInvalidMedication = false;

    medications.forEach((medication) => {
      if (!medication.name.trim()) {
        hasInvalidMedication = true;
      }
    });

    setMissingFields((prev) => ({
      ...prev,
      medications: hasInvalidMedication, // Set missingFields.medications based on validation result
    }));
  };



  return (
    <section
      className={`section ${missingFields.medications ? 'highlight' : ''}`}  // Highlight section if validation fails
      ref={medicationRef}
    >
      <div className="section-content">
        <h2>Current Medications</h2>

        <p>Are you on medication?</p>

        <select value={selection} onChange={handleSelectionChange} className="medication-select">
          {!selection && <option value="">Select</option>}
          <option value="I am on medication">I am on medication</option>
          <option value="I am not on medications">I am not on medication</option>
        </select>

        
        {missingFields.medications && (
          <p className="error-message">Please provide a name for each medication.</p>
        )}
        {!formData.no_medications && (
          <table className="edit-table">
            <thead>
              <tr>
              <th>Medication Name</th>
              <th>Dosage</th>
              <th>Frequency</th>
              </tr>
            </thead>
            <tbody>
              {medications.map((medication, index) => (
                <tr key={index}>
                  <td data-label="Medication Name">
                    <input
                      type="text"
                      value={medication.name}
                      onChange={(e) => handleMedicationChange(e, index, 'name')}
                      placeholder="Medication Name"
                    />
                  </td>
                  <td data-label="Dosage">
                    <input
                      type="text"
                      value={medication.dosage}
                      onChange={(e) => handleMedicationChange(e, index, 'dosage')}
                      placeholder="Dosage"
                    />
                  </td>
                  <td data-label="Frequency">
                    <input
                      type="text"
                      value={medication.frequency}
                      onChange={(e) => handleMedicationChange(e, index, 'frequency')}
                      placeholder="Frequency"
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      className="button-standard remove-button"
                      onClick={() => handleRemoveMedication(index)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {selection === 'I am on medication' && (

        <button type="button" onClick={handleAddMedication} className="button-standard add-button">
          Add Medication
        </button>
        )}
      </div>
    </section>
  );
}

export default Medication;

import React from 'react';



const Surgeries = ({ surgeries }) => {
  const filteredSurgeries = Array.isArray(surgeries)
    ? surgeries.filter(surgery => surgery && surgery.trim())
    : [];

  return (
    <section className="section">
      <div className="section-content">
        <h1>Surgeries</h1>
        {filteredSurgeries.length > 0 ? (
          <div className="conditions-list">
            {filteredSurgeries.map((surgery, index) => (
              <h3 key={index} className="condition-item">{surgery}</h3>
            ))}
          </div>
        ) : (
          <p>No surgeries reported</p>
        )}
      </div>
    </section>
  );
};

export default Surgeries;

import React from 'react';



const ContactModal = ({ contacts, formatPhoneNumber, handleContactSelection, onClose }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Select a Contact</h2>
        {contacts.map((contact, index) => (
          <button
            key={index}
            className="contact-selection-button"
            onClick={() => handleContactSelection(`${contact.countryCode}${contact.number}`)}
          >
            {contact.name}: {formatPhoneNumber(contact.countryCode, contact.number)}
          </button>
        ))}
        <button className="cancel-button" onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

  

export default ContactModal;

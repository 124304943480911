import React from 'react';


const Conditions = ({ conditions }) => {
  return (
    <section className="section">
      <div className="section-content">
        <h1>Medical Conditions</h1>
        <div className="conditions-list">
          {conditions && conditions.length > 0 ? (
            conditions.map((condition, index) => (
              <h3 key={index} className="condition-item">{condition}</h3>
            ))
          ) : (
            <p>No medical conditions reported.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Conditions;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';  
import '../../styles.css';
import logo from '../../assets/defaultProfile.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';


import PersonalInfo from './PersonalInfo';
import Contacts from './Contacts';
import Conditions from './Conditions';
import Surgeries from './Surgeries';
import Medications from './Medications';
import Allergies from './Allergies';
import DNR from './DNR';

import ContactModal from './ContactModal';
import PasswordModal from './PasswordModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import CredentialsModal from './CredentialModal';
import TermsModal from './TermsModal';
import CountryModal from './CountryModal';

// https://medicids-backend-34db21443628.herokuapp.com
function UserPage({ formData, setFormData })  {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { login } = useAuth();  
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasCredentials, setHasCredentials] = useState(false);
  const [password, setPassword] = useState('');
  const [emailForRecovery, setEmailForRecovery] = useState('');
  const [email, setEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(false);
  const [showSetCredentials, setShowSetCredentials] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showContactOptions, setShowContactOptions] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [recoverySuccess, setRecoverySuccess] = useState(null);
  const [showCountryModal, setShowCountryModal] = useState(false);

  const [loginAttempts, setLoginAttempts] = useState(0);
  const [lockoutTime, setLockoutTime] = useState(null);


  // Assigns class to body to apply background image without applying to all pages
  useEffect(() => {
    document.body.classList.add('background-image');

    // Clean up: Remove the class when the component unmounts
    return () => {
      document.body.classList.remove('background-image');
    };
  }, []);

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}`);
        // const response = await fetch(`http://localhost:5000/api/users/uuid/${uuid}`);


        if (!response.ok) {
          throw new Error('User not found');
        }
        const data = await response.json();
        setUserData(data);

        if (data.email && data.password) {
          setHasCredentials(true);
        }
        // Show the country modal if the country value is missing
        if (!data.country) {
          setShowCountryModal(true);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [uuid]);

  // Check if credentials are missing
  useEffect(() => {
    if (userData && (!userData.email || !userData.password)) {
      setShowSetCredentials(true);
    }
  }, [userData]);

  // Lockout handling logic
  useEffect(() => {
    if (lockoutTime) {
      const remainingTime = Math.max(0, (lockoutTime - new Date()) / 1000);
      if (remainingTime <= 0) {
        setLockoutTime(null);
        setLoginAttempts(0);
      } else {
        const timeout = setTimeout(() => setLockoutTime(null), remainingTime * 1000);
        return () => clearTimeout(timeout);
      }
    }
  }, [lockoutTime]);

  const handleEditClick = () => {
    if (lockoutTime) {
      setError(`Too many failed attempts. Please try again in ${Math.ceil((lockoutTime - new Date()) / 1000 / 60)} minutes.`);
      return;
    }

    if (hasCredentials) {
      setShowPasswordPrompt(true);
    } else {
      setShowSetCredentials(true);
    }
  };

  const handleCountrySelection = async (isUK) => {
    console.log("handleCountrySelection triggered with value:", isUK); // Log the function call
    try {
      const updatedCountry = isUK ? 'UK' : 'Not UK';
      console.log("Updating country to:", updatedCountry);
  
      const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}/country`, {
      // const response = await fetch(`http://localhost:5000/api/users/uuid/${uuid}/country`, {

        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ country: updatedCountry }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update user country');
      }
  
      console.log("Country updated successfully in backend.");
  
      // Update local and central formData after successful update
      setUserData((prevData) => ({
        ...prevData,
        country: updatedCountry,
      }));
  
      setFormData((prevData) => ({
        ...prevData,
        country: updatedCountry,
      }));
  
      setShowCountryModal(false);
    } catch (error) {
      console.error('An error occurred while updating user country:', error);
      setError('An error occurred while updating user country.');
    }
  };
  
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setError(null); 

    if (loginAttempts >= 5) {
      setLockoutTime(new Date(new Date().getTime() + 10 * 60000));
      setError('Too many failed attempts. Please try again in 10 minutes.');
      return;
    }

    try {
      const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}/verify-password`, {
      // const response = await fetch(`http://localhost:5000/api/users/uuid/${uuid}/verify-password`, {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      });

      if (response.ok) {
        login();
        navigate(`/edit/${uuid}`);
        window.scrollTo(0, 0);
      } else {
        const result = await response.json();
        setError(result.error || 'Invalid password');
        setLoginAttempts(prev => prev + 1);
      }
    } catch (err) {
      console.error(err);
      setError('An error occurred while verifying the password.');
    }
  };

  const handleSetCredentialsSubmit = async (e) => {
    e.preventDefault();
    setError(null); 

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setError('Invalid email format');
      return;
    }

    if (!termsAccepted) {
      setError('You must accept the terms and conditions');
      return;
    }

    try {
      const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}/set-credentials`, {
      // const response = await fetch(`http://localhost:5000/api/users/uuid/${uuid}/set-credentials`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, termsAccepted }),
      });

      if (response.ok) {
        setUserData({ ...userData, email, password });
        login();
        navigate(`/edit/${uuid}`);
      } else {
        const result = await response.json();
        setError(result.error || 'Failed to set credentials');
      }
    } catch (err) {
      console.error(err);
      setError('An error occurred while setting credentials.');
    }
  };

  const handleAcceptTerms = () => {
    setTermsAccepted(true);
    setShowTermsModal(false);
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError(null); 
    setRecoverySuccess(null); 

    try {
      const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/password-recovery`, {
      //  const response = await fetch(`http://localhost:5000/api/users/password-recovery`, {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: emailForRecovery }),
      });

      if (response.ok) {
        setRecoverySuccess('Recovery email sent successfully.');
        setShowForgotPassword(false);
        setShowPasswordPrompt(false);
      } else {
        const result = await response.json();
        setError(result.error || 'Failed to send recovery email.');
      }
    } catch (err) {
      console.error(err);
      setError('An error occurred while trying to send the recovery email.');
    }
  };

  const calculateAge = (dateOfBirth) => {
    if (!dateOfBirth) return null;

    const dob = new Date(dateOfBirth);
    const now = new Date();
    
    let age = now.getFullYear() - dob.getFullYear();
    let months = now.getMonth() - dob.getMonth();

    if (months < 0 || (months === 0 && now.getDate() < dob.getDate())) {
      age--;
      months += 12;
    }

    if (age >= 18) {
      return `${age}`;
    } else {
      const yearLabel = age === 1 ? 'year' : 'years';
      const monthLabel = months === 1 ? 'month' : 'months';
      return `${age} ${yearLabel} & ${months} ${monthLabel} old`;
    }
  };

  const handleCallClick = () => {
    if (!userData.contacts || userData.contacts.length === 0) {
      alert('No emergency contacts available.');
      return;
    }
  
    // Check if the device is a mobile device
    if (!navigator.userAgent.match(/Mobile|iPhone|Android|BlackBerry|IEMobile|Opera Mini/i)) {
      alert('Calling is only available on mobile devices.');
      return;
    }
  
    const formatPhoneNumber = (contact) => {
      return `${contact.countryCode}${contact.number}`;  // Concatenate country code and number
    };
  
    if (userData.contacts.length === 1) {
      // Call the single contact with country code
      makeCall(formatPhoneNumber(userData.contacts[0]));
    } else {
      // Show options if there are multiple contacts
      setShowContactOptions(true);
    }
  };
  

  const makeCall = (number) => {
    let anonymousNumber = number;
  
    // Add anonymous prefix based on country code
    if (number.startsWith('+1')) {
      // US or Canada
      anonymousNumber = `*67${number}`;
    } else if (number.startsWith('+44')) {
      // UK
      anonymousNumber = `141${number}`;
    }
  
    // Initiate the call with the modified number for anonymous calling
    window.location.href = `tel:${anonymousNumber}`;
  };

  const handleContactSelection = (number) => {
    makeCall(number);
    setShowContactOptions(false);
  };

  // Function to close modals and reset error state and input fields
  const closePasswordPrompt = () => {
    setShowPasswordPrompt(false);
    setError(null); // Reset error state
    setPassword(''); // Clear the password input field
  };

  const closeSetCredentials = () => {
    setShowSetCredentials(false);
    setError(null); // Reset error state
    setEmail(''); // Clear the email input field
    setPassword(''); // Clear the password input field
    setConfirmPassword(''); // Clear the confirm password input field
    setTermsAccepted(false); // Reset the terms accepted checkbox
  };

  const closeForgotPassword = () => {
    setShowForgotPassword(false);
    setError(null); // Reset error state
    setEmailForRecovery(''); // Clear the email for recovery input field
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  function formatPhoneNumber(countryCode, number) {
    if (countryCode === '+1' && number.length === 10) {
      // Format number in +1-XXX-XXX-XXXX
      return `${countryCode}-${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6)}`;
    }
    // Return as is if not +1 or not 10 digits
    return `${countryCode}${number}`;
  }
  
  return (
    <div className="container">
      <header>
        <div className="header-title">
          {/* Circular Profile Image */}
          <div className="image-container">
            <img
              src={
                userData.profile_pic && userData.profile_pic.data
                  ? `https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}/profile-pic` // URL for serving the profile picture
                  : logo // Default logo
              }
              alt="Profile"
              className="profile-image"
            />
          </div>
          <div className="header-div">
          {userData.name && <h1>{userData.name || ''}</h1>}
          {userData.conditions && userData.conditions.length > 0 && (
            <p className="first-condition">{userData.conditions[0]}</p>
          )}
          </div>
        </div>
      </header>




      <main>
        <PersonalInfo 
          userData={userData}
          calculateAge={calculateAge} />

          
        <Contacts
          contacts={userData.contacts}
          formatPhoneNumber={formatPhoneNumber}
          />

        
        <Conditions 
          conditions={userData.conditions}/>

        <Surgeries
          surgeries={userData.surgeries}/>
        
        <Medications 
          noMedications={userData.no_medications}
          medications={userData.medications}/>

        <Allergies
          noAllergies={userData.noAllergies}
          allergies={userData.allergies}/>

        <DNR 
          dnrStatus={userData.dnr_status}
          dnrDocument={userData.dnr_document}
          uuid={uuid}/>

      </main>  

      <footer>
        <p>Last Updated: <span>{userData.last_updated === "Never" || !userData.last_updated ? "Never" : new Date(userData.last_updated).toLocaleDateString()}</span></p>
        <button type="button" className="call-button" onClick={handleCallClick}>Call Emergency Contact</button>
        <button type="button" className="edit-button" onClick={handleEditClick}>Edit  <FontAwesomeIcon icon={faPenToSquare} /></button>
      </footer>

      {showContactOptions && (
        <ContactModal
          contacts={userData.contacts}
          formatPhoneNumber={formatPhoneNumber}
          handleContactSelection={handleContactSelection}
          onClose={() => setShowContactOptions(false)}
        />
        )}

      {showPasswordPrompt && (
        <PasswordModal
          password={password}
          setPassword={setPassword}
          error={error}
          onSubmit={handlePasswordSubmit}
          onCancel={() => setShowPasswordPrompt(false)}
          onForgotPassword={() => {
            setShowPasswordPrompt(false); // Close Password Modal
            setShowForgotPassword(true); // Open Forgot Password Modal
          }}
        />
      )}

      {showForgotPassword && (
        <ForgotPasswordModal
          emailForRecovery={emailForRecovery}
          setEmailForRecovery={setEmailForRecovery}
          error={error}
          recoverySuccess={recoverySuccess}
          onSubmit={handleForgotPassword}
          onCancel={() => setShowForgotPassword(false)}
        />
      )}

      {showSetCredentials && (
        <CredentialsModal
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          termsAccepted={termsAccepted}
          setTermsAccepted={setTermsAccepted}
          error={error}
          onSubmit={handleSetCredentialsSubmit}
          onCancel={closeSetCredentials}
          onShowTerms={() => setShowTermsModal(true)}
        />
      )}

      {showTermsModal && (
        <TermsModal
          onAccept={handleAcceptTerms}
          onCancel={() => setShowTermsModal(false)}
        />
      )}

      {showCountryModal && (
        <CountryModal
        showCountryModal={showCountryModal}
        onCountrySelect={handleCountrySelection}
      />
      )}
    </div>
  );
}

export default UserPage;
